<template>
    <div class="page-wrapper">

        <Header />
        <router-view />
        <!-- <Footer /> -->
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
// import Footer from "./components/Footer.vue";
export default {
    name: "App",
    components: {
        Header,
        // Footer,
    },
};
</script>


<style>
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
</style>

<!-- <style>
 @import "./assets/cssfiles/flaticon.min.css";
@import "./assets/cssfiles/fontawesome-5.14.0.min.css";
@import "./assets/cssfiles/bootstrap.min.css";
@import "./assets/cssfiles/magnific-popup.min.css";
@import "./assets/cssfiles/nice-select.min.css";
@import "./assets/cssfiles/jquery.animatedheadline.css";
@import "./assets/cssfiles/animate.min.css";
@import "./assets/cssfiles/style.css";
@import "./assets/cssfiles/slick.min.css";
</style> -->
<style lang="scss">
 @import "./scss/Main.scss";
</style>
