<template>
    <div class="home">
        <Application />
    </div>
</template>

<script>
import Application from "./Application.vue";
export default {
    name: "HomeView",
    components: {
        Application,

    },
};
</script>
