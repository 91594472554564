<template>
    <div>
        <div class="box">
            <div id="form                                                                                                                                                                                   ulaire">
                <form @submit.prevent="Submit">
                    <div class="container">


                        <div class="columns">
                            <div class="column">
                                <section>
                                    <b-field :label="ChiffreLabel + ' : ' + FormularData.Chiffre + ' €'">
                                        <b-slider v-model="FormularData.Chiffre" :step="1000" :min="6000" :max="45000"
                                            rounded v-on:change="reset()"></b-slider>
                                    </b-field>
                                </section>
                            </div>
                        </div>

                        <div id="message" v-if="isDone" class="message-box-salaire">
                            <div class="notification is-success">
                                Salaire Totale : <strong>{{FormularData.SalaireNet}}</strong> DT <br>
                                <strong>+ Assurances de groupe</strong><br>
                                <strong>+ Ticket Resto</strong><br>
                            </div>
                        </div>

                        <div id="submitform">
                            <button type="submit" class="button" v-on:click="calculer()"> Calculer mon salaire </button>
                        </div>

                        
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
import Buefy from "buefy";
import Vue from "vue";
Vue.use(Buefy, {
    defaultIconPack: "fa",
    defaultContainerElement: "#content",
});
export default {
    name: "ApplicationView",

    data() {
        return {
            isDone: false,
            ChiffreLabel : "Chiffre d'affaires",
            FormularData: {
                Chiffre: 0,
                SalaireNet: 0,
            },
            maxDate: new Date(),
            data: [],
            isFullPage: true,
            isLoading: false,

            Salaires: [
                {
                    chiffre: 6000, salaire: 888.27
                },
                {
                    chiffre:7000,salaire:1109.087
                },
                {
                    chiffre:8000,salaire:1529.46
                },
                {
                    chiffre:9000,salaire:1850.055
                },
                {
                    chiffre:10000,salaire:2953.65
                },
                {
                    chiffre:11000,salaire:3274.245
                },
                {
                    chiffre:12000,salaire:3594.84
                },
                {
                    chiffre:13000,salaire:3915.435
                },
                {
                    chiffre:14000,salaire:4236.03
                },
                {
                    chiffre:15000,salaire:4556.625
                },
                {
                    chiffre:16000,salaire:4877.22
                },
                {
                    chiffre:17000,salaire:5197.815
                },
                {
                    chiffre:18000,salaire:5518.41
                },
                {
                    chiffre:19000,salaire:5839.005
                },
                {
                    chiffre:20000,salaire:6159.6
                },
                {
                    chiffre:21000,salaire:6480.195
                },
                {
                    chiffre:22000,salaire:6800.79
                },
                {
                    chiffre:23000,salaire:7121.385
                },
                {
                    chiffre:24000,salaire:7441.98
                },
                {
                    chiffre:25000,salaire:7762.575
                },
                {
                    chiffre:26000,salaire:8083.17
                },
                {
                    chiffre:27000,salaire:8403.765
                },
                {
                    chiffre:28000,salaire:8724.36
                },
                {
                    chiffre:29000,salaire:9044.955
                },
                {
                    chiffre:30000,salaire:9365.55
                },
                {
                    chiffre:31000,salaire:9686.145
                },
                {
                    chiffre:32000,salaire:10006.74
                },
                {
                    chiffre:33000,salaire:10327.335
                },
                {
                    chiffre:34000,salaire:10647.93
                },
                {
                    chiffre:35000,salaire:10968.525
                },
                {
                    chiffre:36000,salaire:11289.12
                },
                {
                    chiffre:37000,salaire:11609.715
                },
                {
                    chiffre:38000,salaire:11930.31
                },
                {
                    chiffre:39000,salaire:12250.905
                },
                {
                    chiffre:40000,salaire:12571.5
                },
                {
                    chiffre:41000,salaire:12892.095
                },
                {
                    chiffre:42000,salaire:13212.69
                },
                {
                    chiffre:43000,salaire:13533.285
                },
                {
                    chiffre:44000,salaire:13853.88
                },
                {
                    chiffre:45000,salaire:14174.475
                }
            ],
            
            Config: {
                showWeekNumber: false,
            },
        };
    },
    methods: {
        reset() {
            this.isDone = false;
        },
        calculer() {

            let currentChiffre = this.FormularData.Chiffre;
            this.isDone = true;
            this.Salaires.forEach((e) => {
                if(currentChiffre == e.chiffre){
                    this.FormularData.SalaireNet = e.salaire;
                }
            })
        },
        
    }
};

</script>
<style>
.b-slider.is-primary .b-slider-fill {
    background: #0ce7c4 !important;
  }

  

.label {
    margin-right: 5px;
    padding-top: 6px;
}

.button {
    background-color: rgb(38, 105, 146) !important;
    border: medium none !important;
    color: rgb(255, 255, 255) !important;
    margin: auto;
}
#message {
    margin-bottom: 49px;
  }
.button:hover {
    background-color: rgb(64, 128, 169) !important;
}

#submitform {
    text-align: center;
}

.hideclass {
    display: none;
}

.dialog.has-custom-container {
    position: fixed !important;
}

.navbar {
    padding-left: 100px;
  }
</style>
