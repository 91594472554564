import Vue from "vue";
import Router from "vue-router";
import Home from './views/Home.vue'
Vue.use(Router);

const scrollBehavior = () => {
    return {
            top: 0,
            left: 0
            }
};
const router =  new Router({
  linkExactActiveClass: "active",
  mode: 'history',
  routes: [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {title: 'Acceuil - Capiassur Salaires', scrollTop: 0}
      },
  ],
  scrollBehavior
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.scrollTo(0,0);
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Softisme Coding and Consulting | Web Design | Web Development | Mobile Development | UI/UX Design | Outsourcing | Dedicated Team | Project Based Team';
  next();
});
export default router;
